<template>
    <div class="grid help-page">
        <div class="col-12">
            <div class="card help-search">
                <div class="help-search-content">
                    <h1>Estamos aqui para ajudar</h1>
                    <div class="search-container">
                        <i class="pi pi-search"></i>
                        <InputText type="text" placeholder="Pesquisar" />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 lg:col-6">
            <div class="card">
                <h4>Primeiros passos</h4>
                <Accordion :activeIndex="0">
                    <AccordionTab header="Apresentação">
                        <p>Apresentação Plataforma Nesher Representantes</p>
                        <p>Resumo geral de funções básicas para consumir os recursos da plataforma</p>
                        <p>Aconselhamos o uso do Google Chrome ou Mozilla Firefox para acesso a plataforma</p>
                    </AccordionTab>
                    <AccordionTab header="Pedidos">
                        <p>O fluxo de funcionamento é voltado a velocidade de conversão, sendo:</p>
                        <p>Associar cliente a pedido e caso não possua cadastrado você consegue cadastrar;</p>
                        <p>Preencher devidamente as informações operacionais e financeiras e associar produtos a este pedido;</p>
                        <p>CONFIRMAR O PEDIDO.</p>
                    </AccordionTab>
                    <AccordionTab header="Importante">
                        <p>Fique de olho nos indicadores de seu dashboard e notificações.</p>
                        <p>Evite deixar por muito tempo seus Pedidos ou Clientes em elaboração pois com este status as informações não são compartilhadas com o faturamento.</p>
                        <p>Não compartilhe seu usuário e senha com outras pessoas.</p>
                    </AccordionTab>
                </Accordion>

                <h4>Perguntas Frequentes</h4>
                <Accordion :activeIndex="0">
                    <AccordionTab header="Como converter mais rápido meus pedidos">
                        <p>Logo que acionar a função Confirmar Pedido em poucos segundos toda equipe de faturamento tem acesso.</p>
                        <p>Evite deixar seus pedidos em Elaboração por muito tempo e fique de olho nos seus alertas</p>
                    </AccordionTab>
                    <AccordionTab header="Como cadastrar novos clientes">
                        <p>Acessar Clientes</p>
                        <p>Clicar em Novo Cliente</p>
                        <p>Preencher os campos obrigatórios e associar no mínimo 3 referências.</p>
                        <p>Confirmar o cadastro do cliente com a função Confirmar Cliente</p>
                    </AccordionTab>
                    <AccordionTab header="Contato suporte técnico">
                        <p>Primeiro nível de suporte temos o time interno da Nesher sanando situações como criação de novos usuários, alteração de senhas e administração de problemas com cadastros em geral. </p>
                        <p>Segundo nível temos a MobAgile Sistemas (desenvolvedora da plataforma) entregando novas funções e sanando problemas operacionais</p>
                        <p>Terceiro nível temos a Fartec Sistemas nos apoiando com os web services do Protheus (ERP de faturamento da Nesher) </p>
                        <p>Sugestão de novas funcionalidades são bem vindas em suporte@mobagile.com.br</p>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>


        <div class="col-12 lg:col-6">
            <div class="card">
                <h4>Status Protheus</h4>
                <p>Todos os serviços estão opéracionais.</p>
                <div class="status-bars">
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar status-bar-failure"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                    <div class="status-bar"></div>
                </div>
                <div class="status-bar-footer">
                    <span>Histórico 30 dias</span>
                    <span>Hoje</span>
                </div>
            </div>

            <div class="card">
                <h4>Vídeos com Material de Apoio</h4>
                <p>Artigos disponíveis no Youtube.</p>
                <div class="blog-posts">


                    <a @click="abrirVideo1" class="cursor-pointer">
                        <div class="blog-post">
                            <div class="blog-text">
                                <h1>Criando um novo pedido e </h1>
                                <span>acompanhando faturamento de outros</span>
                            </div>
                            <div class="blog-profile">
                                <img src="demo/images/avatar/amyelsner.png" alt="apollo" />
                            </div>
                        </div>
                    </a>


                    <a @click="abrirVideo2" class="cursor-pointer">
                        <div class="blog-post">
                            <div class="blog-text">
                                <h1>Acesso a carteira de clientes e</h1>
                                <span>cadastrando um novo cliente</span>
                            </div>
                            <div class="blog-profile">
                                <img src="demo/images/avatar/annafali.png" alt="apollo" />
                            </div>
                        </div>
                    </a>

                    <a @click="abrirVideo3" class="cursor-pointer">

                        <div class="blog-post">
                            <div class="blog-text">
                                <h1>Acesso a Relatórios Protheus</h1>
                                <span>Novas funcionalidades</span>
                            </div>
                            <div class="blog-profile">
                                <img src="demo/images/avatar/stephenshaw.png" alt="apollo" />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {

    methods: {
        abrirVideo1(){
            window.open('https://youtu.be/QSwvLEMWM7I', '_blank');
        },

        abrirVideo2(){
            window.open('https://youtu.be/J8cQPH4GNPw', '_blank');
        },

        abrirVideo3(){
            window.open('https://youtu.be/ah2koyr87os', '_blank');
        }

    }

}
</script>

<style scoped>

</style>
